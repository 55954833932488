import React from "react"
import { Layout, Awards, Award, Nominations, Nomination, AboutAuthor, KnjizevnaDjela, KnjizevnoDjelo, Title, Scenarij, Scenariji, AutorskiAnimiraniFilm, AutorskiAnimiraniFilmovi, FilmoviGlavniAnimator, FilmGlavniAnimator, FestivaliFilm, FestivaliFilmovi, KnjigaIlustrirao, KnjigeIlustrirao, MedunarodnaIzlozba, MedunarodneIzlozbe } from "../components/index"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';

export default () => (
  <div className="authors">
    <Layout>
          <Helmet>
                <title>Authors - Art Organization Creative House</title>
                <meta name='title' content='Authors - Art Organization Creative House' />
                <meta name='description' content='Creative House is art organization founded in 2018, which deals with the publication and distribution of quality literary works, the production of original animated films and the organization and implementation of creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original art expressions and to expand the space of creative freedom. The founders and main implementers of the program of the Art Organization CREATIVE HOUSE are the multi-award winning writer and screenwriter Nena Lončar and the artist Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
      <Title title='Authors' subtitle='Interesting facts about the authors, their lives and successes'></Title>
      <div className="authors-wrapper">
        <AboutAuthor className='author-nena' idd='nena-loncar' title='Nena Lončar'>
          <p>Screenwriter and multi-award winning writer, she was born in 1968 in Krapina, where she still lives today.</p>
          <p>A member of some societies, but mostly belongs to her family and herself. She loves to read, and by writing she tames her messy thoughts.</p>
          <p>As she creates the stories, Nena plays with words. For her, language is like the Universe - miraculous and infinite. With her imagination she expands the boundaries of our worlds, and with humor she fights against prejudice and stupidity.</p>
          <p>In her work she explores innovative approaches. Ludicism is not foreign to her either. She loves to write for children and enjoys it.</p>
        </AboutAuthor>
        <KnjizevnaDjela>
          <KnjizevnoDjelo>
            “<strong>Six leprous spells and one squared horror</strong>”, <em>HDKDM</em>, 2014. - <strong>novel</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>I was a dog and I am again</strong>”, <em>HDKDM</em>, 2015. - <strong>novel</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Fairy tale about a hat that had a man</strong>”, <em>HDKDM</em>, 2017.  - <strong>alegorija</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>All about Eva</strong>”, <em>Ibis grafika</em>, 2017. - <strong>novel</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Konrad's bird shop</strong>”, <em>HDKDM</em>, 2018. - <strong>picture book</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>City on a grain of salt</strong>”, <em>Creative House</em>, 2018. - <strong>novel</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Fish that could not cry</strong>”, <em>Creative House</em>, 2019. - <strong>picture book</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>The bravest mouse in the world</strong>”, <em>Creative House</em>, 2019. - <strong>picture book</strong>
          </KnjizevnoDjelo>
        </KnjizevnaDjela>

        <Scenariji>
          <Scenarij>Scenarios for <em>four episodes</em> of the animated series “<strong>Professor Balthazar</strong>”, <em>2005.</em>
            <ul className='scenarij-sublist'>
              <li>”<strong>A Small problem</strong>”</li>
              <li>”<strong>Universal Void</strong>”</li>
              <li>”<strong>Cactus Hombre</strong>”</li>
              <li>”<strong>Robot Spirit</strong>”</li>
            </ul>
          </Scenarij>
          <Scenarij>screenplay for <em>the animated film</em> ”<strong>Konrad’s Bird Shop</strong>” (supported by <em>HAVC</em>), <em>2018.</em></Scenarij>
          <Scenarij>screenplay for <em>the animated film</em> “<strong>Tuba player</strong>” (supported by <em>HAVC</em>), <em>2019.</em></Scenarij>
        </Scenariji>

        <Awards>
          <Award>Award <b>“Zvonko”</b> for a novel <i>“Six leprous spells and one squared horror”</i></Award>
          <Award>Award <b>“Grigor Vitez”</b> for a novel <i>“All about Eva”</i></Award>
          <Award>Award <b>“Zvonko”</b> for a novel <i>“I was a dog and I am again”</i></Award>
          <Award>International Award <b>“The Little Prince</b> for a novel <i>“All about Eva”</i></Award>
        </Awards>

        <Nominations>
          <Nomination>
            Nominations for the <strong>“Grigor Vitez”</strong> and <strong>"The Little Prince”</strong> awards for the novel "I was a dog and I am again"
          </Nomination>
          <Nomination>
            Nominations for the <strong>“Anto Gardaš”</strong> award for the novel “We adopted grandma and grandpa”
          </Nomination>
          <Nomination>
            Nominations for the <strong>“Anto Gardaš”</strong> and <strong>“Mato Lovrak”</strong> awards for the novel “All About Eva”
          </Nomination>
          <Nomination>
            Nominations for the <strong>”Sheep in a Box”</strong> award for the picture book “Konrad's Bird Shop”
          </Nomination>
          <Nomination>
            Award <strong>“Croatian beautiful book for 2017”</strong> for the allegory "Fairy tale about a hat that had a man" awarded as part of the international competition "Best Book Design from all over the World"
          </Nomination>
          <Nomination>
          The novels “I was a dog and I am again” and “All about Eva” were included in the <strong>“National Quiz to Encourage Reading”</strong> for 2016. and 2018.
          </Nomination>
          <Nomination>
          All literary works published so far are included in the <strong>“Catalog of Good Books”</strong> which are recommended for reading by the profession
            </Nomination>
          <Nomination>
          She is the winner of the literary scholarship of the Ministry of Culture for 2020
            </Nomination>
        </Nominations>
  
        <AboutAuthor className='author-marijan' idd='marijan-loncar' title='Marijan Lončar'>
          <p>Marijan Lončar (1967) is an award-winning animator and author of animated films.</p>
          <p>He is a member of HZSU, HDFD and ASIFA.</p>
          <p>Over the years, he has worked as the main animator on many films, the most important of which are: Little Flying Bears, The Strange Adventures of Apprentice Hlapić, Professor Balthazar, Contact, Opening credits for the World Animated Film Festival - Animafest.</p>
          <p>He also made several original animated films: Happy Cricket, Banana, Christmas Fairy Tale.</p>
        </AboutAuthor>
        <Awards>
          <Award>Prizes <b>GRAND PRIX</b> at the <i> Croatian Animation Day</i> in 2004 for the film <strong>HAPPY CRICKET</strong></Award>
        </Awards>

        <AutorskiAnimiraniFilmovi>
          <AutorskiAnimiraniFilm>"Happy Cricket"</AutorskiAnimiraniFilm>
          <AutorskiAnimiraniFilm> “Banana”</AutorskiAnimiraniFilm>
          <AutorskiAnimiraniFilm> “Christmas fairy tale”</AutorskiAnimiraniFilm>
        </AutorskiAnimiraniFilmovi>


        <FilmoviGlavniAnimator>
          <FilmGlavniAnimator>“Little flying bears”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“The Strange Adventures of Šegrt Hlapić”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Professor Balthazar”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Contact”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Banana”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Opening credits for the world festival of animated film Animafest”</FilmGlavniAnimator>
        </FilmoviGlavniAnimator>

        <FestivaliFilmovi>
          <FestivaliFilm>Tabor film festival</FestivaliFilm>
          <FestivaliFilm>Croatian Film Days</FestivaliFilm>
          <FestivaliFilm>One-minute film festival in Požega</FestivaliFilm>
          <FestivaliFilm>Croatian animation day</FestivaliFilm>
          <FestivaliFilm>Animafest Zagreb</FestivaliFilm>
        </FestivaliFilmovi>

        <KnjigeIlustrirao>
          <KnjigaIlustrirao>"I was a dog and I am again"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"We adopted grandma and grandpa"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"All about Eva"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Konrad's bird shop"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Fish that could not cry"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"How Phlegmarin Became Captain"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"The bravest mouse in the world"</KnjigaIlustrirao>
        </KnjigeIlustrirao>

        <MedunarodneIzlozbe>
          <MedunarodnaIzlozba>The book "Fairy tale about a hat that had a man" was chosen as one of the "Croatian beautiful books" for 2017 as part of the international competition "Best Book Design from all over the World", and as such participated in the exhibition in Leipzig, and will be in the permanent exhibition "Book Art International", which opens in the fall as part of the Frankfurt Book Fair.</MedunarodnaIzlozba>
          <MedunarodnaIzlozba>Exhibition of caricatures in “50th World Gallery of Cartoons Skopje 2018“</MedunarodnaIzlozba>
        </MedunarodneIzlozbe>
      </div>
    </Layout>
  </div>
)